.vegas-overlay, .vegas-slide, .vegas-slide-inner, .vegas-timer, .vegas-wrapper {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.vegas-overlay {
  opacity: .5;
  background: url("02.0f8f39bb.png") center;
}

.vegas-timer {
  height: 2px;
  top: auto;
  bottom: 0;
}

.vegas-timer-progress {
  background: #fff;
  width: 0;
  height: 100%;
  transition: width ease-out;
}

.vegas-timer-running .vegas-timer-progress {
  width: 100%;
}

.vegas-slide, .vegas-slide-inner {
  background: center no-repeat;
  margin: 0;
  padding: 0;
  transform: translateZ(0);
}

body .vegas-container {
  position: relative;
  overflow: hidden !important;
}

.vegas-video {
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
}

body.vegas-container {
  z-index: -2;
  position: static;
  overflow: auto;
}

body.vegas-container > .vegas-overlay, body.vegas-container > .vegas-slide, body.vegas-container > .vegas-timer {
  z-index: -1;
  position: fixed;
}

:root body.vegas-container > .vegas-overlay, :root body.vegas-container > .vegas-slide {
  bottom: -76px;
}

_::full-page-media {
  bottom: -76px;
}

_:future {
  bottom: -76px;
}

.vegas-transition-fade, .vegas-transition-fade2 {
  opacity: 0;
}

.vegas-transition-fade-in, .vegas-transition-fade2-in {
  opacity: 1;
}

.vegas-transition-fade2-out {
  opacity: 0;
}

.vegas-transition-blur, .vegas-transition-blur2 {
  opacity: 0;
  filter: blur(32px);
}

.vegas-transition-blur-in, .vegas-transition-blur2-in {
  opacity: 1;
  filter: blur();
}

.vegas-transition-blur2-out {
  opacity: 0;
}

.vegas-transition-flash, .vegas-transition-flash2 {
  opacity: 0;
  filter: brightness(25);
}

.vegas-transition-flash-in, .vegas-transition-flash2-in {
  opacity: 1;
  filter: brightness();
}

.vegas-transition-flash2-out {
  opacity: 0;
  filter: brightness(25);
}

.vegas-transition-negative, .vegas-transition-negative2 {
  opacity: 0;
  filter: invert();
}

.vegas-transition-negative-in, .vegas-transition-negative2-in {
  opacity: 1;
  filter: invert(0);
}

.vegas-transition-negative2-out {
  opacity: 0;
  filter: invert();
}

.vegas-transition-burn, .vegas-transition-burn2 {
  opacity: 0;
  filter: contrast(1000%) saturate(1000%);
}

.vegas-transition-burn-in, .vegas-transition-burn2-in {
  opacity: 1;
  filter: contrast() saturate();
}

.vegas-transition-burn2-out {
  opacity: 0;
  filter: contrast(1000%) saturate(1000%);
}

.vegas-transition-slideLeft, .vegas-transition-slideLeft2 {
  transform: translateX(100%);
}

.vegas-transition-slideLeft-in, .vegas-transition-slideLeft2-in {
  transform: translateX(0%);
}

.vegas-transition-slideLeft2-out, .vegas-transition-slideRight, .vegas-transition-slideRight2 {
  transform: translateX(-100%);
}

.vegas-transition-slideRight-in, .vegas-transition-slideRight2-in {
  transform: translateX(0%);
}

.vegas-transition-slideRight2-out {
  transform: translateX(100%);
}

.vegas-transition-slideUp, .vegas-transition-slideUp2 {
  transform: translateY(100%);
}

.vegas-transition-slideUp-in, .vegas-transition-slideUp2-in {
  transform: translateY(0%);
}

.vegas-transition-slideDown, .vegas-transition-slideDown2, .vegas-transition-slideUp2-out {
  transform: translateY(-100%);
}

.vegas-transition-slideDown-in, .vegas-transition-slideDown2-in {
  transform: translateY(0%);
}

.vegas-transition-slideDown2-out {
  transform: translateY(100%);
}

.vegas-transition-zoomIn, .vegas-transition-zoomIn2 {
  opacity: 0;
  transform: scale(0);
}

.vegas-transition-zoomIn-in, .vegas-transition-zoomIn2-in {
  opacity: 1;
  transform: scale(1);
}

.vegas-transition-zoomIn2-out, .vegas-transition-zoomOut, .vegas-transition-zoomOut2 {
  opacity: 0;
  transform: scale(2);
}

.vegas-transition-zoomOut-in, .vegas-transition-zoomOut2-in {
  opacity: 1;
  transform: scale(1);
}

.vegas-transition-zoomOut2-out {
  opacity: 0;
  transform: scale(0);
}

.vegas-transition-swirlLeft, .vegas-transition-swirlLeft2 {
  opacity: 0;
  transform: scale(2)rotate(35deg);
}

.vegas-transition-swirlLeft-in, .vegas-transition-swirlLeft2-in {
  opacity: 1;
  transform: scale(1)rotate(0);
}

.vegas-transition-swirlLeft2-out, .vegas-transition-swirlRight, .vegas-transition-swirlRight2 {
  opacity: 0;
  transform: scale(2)rotate(-35deg);
}

.vegas-transition-swirlRight-in, .vegas-transition-swirlRight2-in {
  opacity: 1;
  transform: scale(1)rotate(0);
}

.vegas-transition-swirlRight2-out {
  opacity: 0;
  transform: scale(2)rotate(35deg);
}

.vegas-animation-kenburns {
  animation: ease-out kenburns;
}

@keyframes kenburns {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.vegas-animation-kenburnsUp {
  animation: ease-out kenburnsUp;
}

@keyframes kenburnsUp {
  0% {
    transform: scale(1.5)translate(0, 10%);
  }

  100% {
    transform: scale(1)translate(0);
  }
}

.vegas-animation-kenburnsDown {
  animation: ease-out kenburnsDown;
}

@keyframes kenburnsDown {
  0% {
    transform: scale(1.5)translate(0, -10%);
  }

  100% {
    transform: scale(1)translate(0);
  }
}

.vegas-animation-kenburnsLeft {
  animation: ease-out kenburnsLeft;
}

@keyframes kenburnsLeft {
  0% {
    transform: scale(1.5)translate(10%);
  }

  100% {
    transform: scale(1)translate(0);
  }
}

.vegas-animation-kenburnsRight {
  animation: ease-out kenburnsRight;
}

@keyframes kenburnsRight {
  0% {
    transform: scale(1.5)translate(-10%);
  }

  100% {
    transform: scale(1)translate(0);
  }
}

.vegas-animation-kenburnsUpLeft {
  animation: ease-out kenburnsUpLeft;
}

@keyframes kenburnsUpLeft {
  0% {
    transform: scale(1.5)translate(10%, 10%);
  }

  100% {
    transform: scale(1)translate(0);
  }
}

.vegas-animation-kenburnsUpRight {
  animation: ease-out kenburnsUpRight;
}

@keyframes kenburnsUpRight {
  0% {
    transform: scale(1.5)translate(-10%, 10%);
  }

  100% {
    transform: scale(1)translate(0);
  }
}

.vegas-animation-kenburnsDownLeft {
  animation: ease-out kenburnsDownLeft;
}

@keyframes kenburnsDownLeft {
  0% {
    transform: scale(1.5)translate(10%, -10%);
  }

  100% {
    transform: scale(1)translate(0);
  }
}

.vegas-animation-kenburnsDownRight {
  animation: ease-out kenburnsDownRight;
}

@keyframes kenburnsDownRight {
  0% {
    transform: scale(1.5)translate(-10%, -10%);
  }

  100% {
    transform: scale(1)translate(0);
  }
}
/*# sourceMappingURL=index.852346bb.css.map */
